import { productListReducer, productDetailsReducer } from "./productReducers";

import {
  // userDetailsReducer,
  userLoginReducer,
  userRegisterReducer,
  userUpdateProfileReducer,
} from "./userReducers";

import {
  orderCreateReducer,
  orderDetailsReducer,
  orderListMyReducer,
  orderPreviewReducer,
  payOnlineRequestReducer,
} from "./orderReducers";
import cartReducer from "./cartReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  // userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,

  orderPreview: orderPreviewReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  payOnlineRequest: payOnlineRequestReducer,
  orderListMy: orderListMyReducer,

  cartData: cartReducer,
});

export default rootReducer;
