export const ORDER_PREVIEW_REQUEST = "ORDER_PREVIEW_REQUEST";
export const ORDER_PREVIEW_SUCCESS = "ORDER_PREVIEW_SUCCESS";
export const ORDER_PREVIEW_FAIL = "ORDER_PREVIEW_FAIL";

export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const PAY_ONLINE_REQUEST = "PAY_ONLINE_REQUEST";
export const PAY_ONLINE_SUCCESS = "PAY_ONLINE_SUCCESS";
export const PAY_ONLINE_FAIL = "PAY_ONLINE_FAIL";
export const PAY_ONLINE_RESET = "PAY_ONLINE_RESET";
